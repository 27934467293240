import axios from 'axios';
import { useEmail, useEmailDispatch } from './EmailContext';
import { useNotificationsDispatch } from 'components/notifications/NotificationsContext';
import { Dialog } from 'primereact/dialog';
import Button from 'components/buttons/Button';
import InputField from 'components/fields/InputField';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Label from '../fields/Label';

const EmailModal = () => {
  const email = useEmail();
  const emailDispatch = useEmailDispatch();
  const notify = useNotificationsDispatch();

  if (!email.visible) {
    return;
  }

  const setData = (k, v) => {
    emailDispatch({
      type: 'update',
      email: {
        ...email,
        [k]: v,
      },
    });
  };

  const close = () => {
    emailDispatch({
      type: 'hide',
    });
  };

  const send = () => {
    axios
      .post(process.env.REACT_APP_API_URL + '/email/send', email)
      .then(() => {
        close();

        notify({
          message: email.auction_id ? 'Emails sent.' : 'Email sent.',
          title: 'Success!',
          type: 'success',
        });
      })
      .catch(() => {
        alert('Failed to send!');
      });
  };

  const header = <div className="text-lg font-bold">{email.modal.title}</div>;

  const footer = (
    <div>
      <Button color="outline" onClick={close}>
        Cancel
      </Button>
      <Button onClick={send}>Send</Button>
    </div>
  );

  return (
    <Dialog
      modal
      draggable={false}
      resizable={false}
      visible={email.visible}
      header={header}
      footer={footer}
      onHide={() => close()}
      style={{ width: '35rem' }}
      contentClassName="overflow-visible"
    >
      <div className="flex flex-col justify-center gap-4">
        <div>
          <InputField
            label="Subject"
            id="email_subject"
            value={email.subject || ''}
            onChange={(e) => setData('subject', e.target.value)}
          />
        </div>

        <div>
          <InputField
            label="CC"
            id="email_cc"
            value={email.cc || ''}
            onChange={(e) => setData('cc', e.target.value)}
          />
        </div>

        <div>
          <InputField
            label="BCC"
            id="email_bcc"
            value={email.bcc || ''}
            onChange={(e) => setData('bcc', e.target.value)}
          />
        </div>

        <div>
          <Label>Message</Label>

          <ReactQuill
            theme="snow"
            className="mt-2"
            value={email.message || ''}
            onChange={(v) => setData('message', v)}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default EmailModal;
