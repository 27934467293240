import AuctionLayout from './components/AuctionLayout'
import ReleaseAuthorizationsModal from './components/modals/ReleaseAuthorizationsModal'
import CaptureDepositsModal from './components/modals/CaptureDepositsModal'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { get, invoices } from 'services/auctions'
import InvoicesTable from '../invoices/components/InvoicesTable'
import SplitButton from 'components/buttons/SplitButton'
import { can, useAuth } from 'components/auth/AuthContext'

const Invoices = () => {
    const { id } = useParams()
    const [ auction, setAuction ] = useState(null)
    const [ showReleaseAuthorizationsModal, setShowReleaseAuthorizationsModal ] = useState(false)
    const [ showCaptureDepositsModal, setShowCaptureDepositsModal ] = useState(false)
    const [ timestamp, setTimestamp ] = useState(null)

    const auth = useAuth()
    const canUpdate = can(auth, 'update_invoices')

    useEffect(() => {
        get(id, ({ data }) => {
            setAuction(data.auction)
        })
    }, [])

    const buttons = canUpdate ? (
        <div className="ml-2 w-full flex justify-start gap-4 items-center">
            <SplitButton
                items={[
                    { name: 'Release Authorizations', onClick: () => {
                        setShowReleaseAuthorizationsModal(true)
                    } }
                ]}
                onClick={() => {
                    setShowCaptureDepositsModal(true)
                }}
            >
                <span className="flex gap-2 items-center">
                    <span>Capture Deposits</span>
                </span>
            </SplitButton>
        </div>
    ) : ''

    const afterAction = () => {
        setTimestamp(Date.now().toString())
    }

    return (
        <AuctionLayout id={id} auction={auction} setAuction={setAuction} buttons={buttons}>
            <InvoicesTable
                loadInvoices={(params, then) => invoices(id, params, then)}
                timestamp={timestamp}
                auction={auction}
            />

            <ReleaseAuthorizationsModal
                auction={auction}
                visible={showReleaseAuthorizationsModal}
                setVisible={setShowReleaseAuthorizationsModal}
                then={afterAction}
            />

            <CaptureDepositsModal
                auction={auction}
                visible={showCaptureDepositsModal}
                setVisible={setShowCaptureDepositsModal}
                then={afterAction}
            />
        </AuctionLayout>
    )
}

export default Invoices