import AuctionLayout from '../auctions/components/AuctionLayout'
import LotForm from './components/LotForm'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { get as getAuction } from 'services/auctions'
import { create } from 'services/lots'
import { useNotificationsDispatch } from 'components/notifications/NotificationsContext'

const Create = () => {
    const navigate = useNavigate()
    const notify = useNotificationsDispatch()
    const { id } = useParams()
    const defaultLot = {
        auction_id: id,
        item: {
            qty: 1,
            key: 'no',
            runs: 'unknown',
            drives: 'unknown'
        },
        config: {
            show_reserve_status: true
        },
        qty: 1
    }
    const [ lot, setLot ] = useState(defaultLot)
    const [ auction, setAuction ] = useState(null)

    useEffect(() => {
        getAuction(id, ({ data }) => {
            setAuction(data.auction)
            setLot({
                ...defaultLot,
                starting_bid: data.auction.config?.starting_bid
            })
        })
    }, [])

    const submit = (add = false, then, fail) => {
        create(lot, ({ data }) => {
            if (add) {
                setLot(defaultLot)
                window.scrollTo(0, 0)
            } else {
                navigate(`/admin/auction/${data.lot.auction_id}/lots`)
            }

            notify({
                message: 'Lot created.',
                title: 'Success!',
                type: 'success',
            })

            if (then) {
                then(data)
            }
        }, fail)
    }

    return (
        <AuctionLayout id={id} auction={auction} setAuction={setAuction}>
            <LotForm lot={lot}
                     setLot={setLot}
                     auction={auction}
                     setAuction={setAuction}
                     submit={submit}
            />
        </AuctionLayout>
    )
}

export default Create