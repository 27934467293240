import { Link, useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { get, update } from 'services/statements'
import Breadcrumbs from 'components/navbar/Breadcrumbs'
import { formatCurrency } from 'helpers/currency'
import { FaExternalLinkAlt } from 'react-icons/fa'
import {
    FaPencil,
    FaPlus
} from 'react-icons/fa6'
import ModalBillingAddress from '../invoices/components/ModalBillingAddress'
import ModalPremium from '../invoices/components/ModalPremium'
import ModalTax from '../invoices/components/ModalTax'
import { can, useAuth } from 'components/auth/AuthContext'

const Edit = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const [ statement, setStatement ] = useState(null)

    const [showBillingModal, setShowBillingModal] = useState(false)
    const [showPremiumModal, setShowPremiumModal] = useState(false)
    const [showTaxModal, setShowTaxModal] = useState(false)

    const [modalFieldValues, setModalFieldValues] = useState({})

    const auth = useAuth()
    const canEdit = can(auth, 'update_statements')

    useEffect(() => {
        get(id, ({ data }) => {
            setStatement(data.statement)
        })
    }, [])

    if (! statement) {
        return
    }

    const billingAddress = statement.addresses.filter(address => address.type === 'Billing')[0]

    const totals = {
        qty: 0,
        bid: 0,
        premium: 0,
        tax: 0,
        expenses: 0,
        total: 0
    }

    const handleEditClick = (fieldValues, modal) => {
        setModalFieldValues(fieldValues);
        if (modal === 'billing') {
            setShowBillingModal(true);
            return;
        }

        if (modal === 'premium') {
            setShowPremiumModal(true);
            return;
        }

        if (modal === 'tax') {
            setShowTaxModal(true);
            return;
        }
    };

    const setData = (key, value) => {
        setModalFieldValues({
            ...modalFieldValues,
            [key]: value
        });
    };

    const handleSave = async (newValues, type) => {
        // Update the statement with the new values
        const updatedStatement = {
            ...statement,
            ...newValues,
        };

        update(id, {...newValues, type: type}, ({ data }) => {
            navigate('/admin/statements/')
        })

        setStatement(updatedStatement);
    };

    const handleSavePremium = async (newValues) => {
        console.log(newValues)
    }

    const handleSaveTax = async (newValues) => {
        console.log(newValues)
    }

    return (
        <div>
            <ModalBillingAddress
                show={showBillingModal}
                onClose={() => setShowBillingModal(false)}
                fieldValues={modalFieldValues}
                onSave={handleSave}
                setData={setData}
            />

            <ModalPremium
                show={showPremiumModal}
                onClose={() => setShowPremiumModal(false)}
                fieldValues={modalFieldValues}
                onSave={handleSavePremium}
                setData={setData}
            />

            <ModalTax
                show={showTaxModal}
                onClose={() => setShowTaxModal(false)}
                fieldValues={modalFieldValues}
                onSave={handleSaveTax}
                setData={setData}
            />

            <div className="ml-[11px] mt-[-72px] fixed z-10">
                <Breadcrumbs trail={[
                    {
                        href: "/admin/auctions",
                        title: "Auctions"
                    },
                    {
                        href: `/admin/auction/${statement.auction_id}`,
                        title: statement.auction.name
                    },
                    {
                        href: `/admin/auction/${statement.auction_id}/statements`,
                        title: "Statements"
                    },
                    {
                        href: "#",
                        title: `Statement #${statement.number}`
                    }
                ]} />
            </div>

            <div className="mt-3 w-full">
                <div className="pt-6 flex justify-center gap-12 items-start">
                    <div>
                        <div className="text-lg font-bold flex gap-2 items-center">
                            Billing Address

                            {canEdit && (
                                <button
                                    onClick={() => handleEditClick({
                                        first_name: billingAddress?.first_name || '',
                                        last_name: billingAddress?.last_name || '',
                                        address_line1: billingAddress?.address_line1 || '',
                                        address_line2: billingAddress?.address_line2 || '',
                                        city: billingAddress?.city || '',
                                        state: billingAddress?.state || '',
                                        zip: billingAddress?.zip || ''
                                    }, 'billing')}
                                    className="text-slate-400 hover:text-slate-600">
                                    <FaPencil className="w-4 h-4" />
                                </button>
                            )}

                            <Link to={`/admin/company/${statement.consignor_id}/edit`}
                                  className="text-slate-400 hover:text-slate-600">
                                <FaExternalLinkAlt className="w-4 h-4" />
                            </Link>
                        </div>
                        {billingAddress ? (
                            <div>
                                {billingAddress.first_name} {billingAddress.last_name}<br />
                                {billingAddress.address_line1}<br />
                                {billingAddress.address_line2 && (
                                    <div>
                                        {billingAddress.address_line2}
                                    </div>
                                )}
                                {billingAddress.city}, {billingAddress.state} {billingAddress.zip}
                            </div>
                        ) : 'N/A'}
                    </div>
                    <div>
                        {statement.dates.created}
                    </div>
                    <div className="uppercase text-2xl font-bold">
                        {statement.status}
                    </div>
                </div>
                <table className="mt-6 w-full text-right">
                    <thead>
                    <tr className="bg-slate-200">
                        <th className="px-4 py-2 text-left">Description</th>
                        <th className="px-4 py-2">Qty.</th>
                        <th className="px-4 py-2">Bid</th>
                        <th className="px-4 py-2">Premium</th>
                        <th className="px-4 py-2">Tax</th>
                        <th className="px-4 py-2">Expenses</th>
                        <th className="px-4 py-2">Total</th>
                    </tr>
                    </thead>
                    <tbody>
                    {statement.items.map(item => {
                        totals.qty += item.qty
                        totals.bid += item.bid
                        totals.premium += item.premium
                        totals.tax += item.tax
                        totals.expenses += item.expenses
                        totals.total += item.total

                        return (
                            <tr key={item.id}>
                                <td className="px-4 py-2 text-left">
                                    <b>
                                        <Link
                                            to={`/admin/lot/${item.lot_id}`}
                                            className="text-slate-400 hover:text-slate-600"
                                        >{item.title}</Link>
                                    </b><br />
                                    {item.description}
                                </td>
                                <td className="px-4 py-2 align-top">{item.qty}</td>
                                <td className="px-4 py-2 align-top">{formatCurrency(item.bid, 2)}</td>
                                <td className="px-4 py-2 align-top">
                                    <div>
                                        {formatCurrency(item.premium, 2)}
                                    </div>

                                    {canEdit && (
                                        <button onClick = {() => handleEditClick({
                                            premium: item.premium
                                        }, 'premium')}
                                                title="Override Premium"
                                                className="text-sm inline-flex gap-1 items-center text-slate-400 hover:text-slate-600"
                                        >
                                            <span>Edit</span>
                                            <FaPencil className="w-3 h-3" />
                                        </button>
                                    )}
                                </td>
                                <td className="px-4 py-2 align-top">
                                    <div>
                                        {formatCurrency(item.tax, 2)}
                                    </div>

                                    {canEdit && (
                                        <button onClick = {() => handleEditClick({
                                            tax: item.tax
                                        }, 'tax')}
                                                title="Edit Tax Rate"
                                                className="text-sm inline-flex gap-1 items-center text-slate-400 hover:text-slate-600"
                                        >
                                            <span>Edit</span>
                                            <FaPencil className="w-3 h-3" />
                                        </button>
                                    )}
                                </td>
                                <td className="px-4 py-2 align-top">
                                    <div>
                                        {formatCurrency(item.expenses, 2)}
                                    </div>

                                    {canEdit && (
                                        <button onClick={() => {}}
                                                title="Add Lot Expense"
                                                className="text-sm inline-flex gap-1 items-center text-slate-400 hover:text-slate-600"
                                        >
                                            <span>Add</span>
                                            <FaPlus className="w-3 h-3" />
                                        </button>
                                    )}
                                </td>
                                <td className="px-4 py-2 align-top">{formatCurrency(item.total, 2)}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                    <tfoot>
                    <tr>
                        <th className="px-4 py-2 text-left">Totals</th>
                        <th className="px-4 py-2">{totals.qty}</th>
                        <th className="px-4 py-2">{formatCurrency(totals.bid, 2)}</th>
                        <th className="px-4 py-2">{formatCurrency(totals.premium, 2)}</th>
                        <th className="px-4 py-2">{formatCurrency(totals.tax, 2)}</th>
                        <th className="px-4 py-2">{formatCurrency(totals.expenses, 2)}</th>
                        <th className="px-4 py-2">{formatCurrency(totals.total, 2)}</th>
                    </tr>
                    </tfoot>
                </table>
                <div className="mt-6 flex flex-col items-end gap-4 text-right">
                    <div>
                        <div className="text-lg font-bold flex gap-2 items-center">
                            <span>
                                Expenses
                            </span>

                            {canEdit && (
                                <button onClick={() => {}}
                                        title="Add Expense"
                                        className="text-white text-sm inline-flex gap-1 items-center bg-slate-400 hover:bg-slate-600 rounded px-1.5 py-1"
                                >
                                    <FaPlus className="w-4 h-4" />
                                </button>
                            )}
                        </div>
                        <em>None</em>
                    </div>

                    <div>
                        <div className="text-lg font-bold flex gap-2 items-center">
                            <span>
                                Payments
                            </span>

                            {canEdit && (
                                <button onClick={() => {}}
                                        title="Add Payment"
                                        className="text-white text-sm inline-flex gap-1 items-center bg-slate-400 hover:bg-slate-600 rounded px-1.5 py-1"
                                >
                                    <FaPlus className="w-4 h-4" />
                                </button>
                            )}
                        </div>
                        <em>None</em>
                    </div>

                    <table className="w-1/4">
                        <tbody>
                        <tr>
                            <td>Subtotal:</td>
                            <td>{formatCurrency(statement.subtotal, 2)}</td>
                        </tr>
                        <tr>
                            <td>Expenses:</td>
                            <td>{formatCurrency(statement.expenses, 2)}</td>
                        </tr>
                        <tr className="border border-0 border-b border-slate-300">
                            <th>Statement Total:</th>
                            <th>{formatCurrency(statement.total, 2)}</th>
                        </tr>
                        <tr>
                            <td>Payments:</td>
                            <td>{formatCurrency(statement.payments, 2)}</td>
                        </tr>
                        <tr>
                            <td>Balance:</td>
                            <td>{formatCurrency(statement.balance, 2)}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Edit