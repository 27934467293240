import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { get, update } from 'services/auctions'
import { can, useAuth } from 'components/auth/AuthContext'
import AuctionLayout from 'views/admin/auctions/components/AuctionLayout'
import {
    FaChalkboardUser,
    FaChartLine,
    FaCheck,
    FaCircleDollarToSlot,
    FaDoorOpen,
    FaEye,
    FaFileInvoice,
    FaFileInvoiceDollar,
    FaStreetView,
    FaTags,
    FaUserCheck
} from 'react-icons/fa6'

import {
    MdBusiness,
    MdGavel
} from 'react-icons/md'
import ActionPanel from 'components/panels/ActionPanel'
import AlertForm from 'components/notes/AlertForm'
import DeleteAuctionModal from './components/modals/DeleteAuctionModal'
import PauseAuctionModal from './components/modals/PauseAuctionModal'
import ResumeAuctionModal from './components/modals/ResumeAuctionModal'
import { AuctionStats } from './components/AuctionStats'
import Button from 'components/buttons/Button'
import { formatCurrency } from 'helpers/currency'

const Dashboard = () => {
    const navigate = useNavigate()

    const auth = useAuth()
    const canUpdateUnrestricted = can(auth, 'update_auctions_unrestricted')
    const canDelete = can(auth, 'delete_auctions')

    const { id } = useParams()
    const [ auction, setAuction ] = useState(null)
    const [ stats, setStats ] = useState([])
    const [ visibleModal, setVisibleModal ] = useState(null)

    useEffect(() => {
        get(id, ({ data }) => {
            if (data.auction.is_template) {
                navigate(`/admin/auction/${id}/edit`)
            }

            setAuction(data.auction)
        })
    }, [id])

    useEffect(() => {
        if (! auction) {
            return
        }

        setStats(
            buildStats()
        )
    }, [auction])

    const buildStats = () => {
        return [
            {
                icon: FaTags,
                title: 'Lots',
                value: auction.counts.lots,
                sub: auction.stats.lots_with_bids + ' w/ bids'
            },
            {
                icon: FaCheck,
                title: 'Lots Sold',
                value: auction.stats.lots_sold
            },
            {
                icon: FaChartLine,
                title: 'Bid Count',
                value: auction.counts.bids
            },
            {
                icon: FaCircleDollarToSlot,
                title: 'Bid Total',
                value: formatCurrency(auction.stats.bid_total, 2) || '$0.00',
                sub: formatCurrency(auction.stats.bid_total, 2) || '$0.00'
            },
            {
                icon: MdGavel,
                title: 'Bidders With Bids',
                value: auction.stats.bidders_with_bids,
                sub: auction.stats.winning_bidders + ' winning'
            },
            {
                icon: FaDoorOpen,
                title: 'Auction View Count',
                value: auction.stats.auction_views
            },
            {
                icon: FaStreetView,
                title: 'Lot View Count',
                value: auction.stats.lot_views
            },
            {
                icon: FaEye,
                title: 'Watch Count',
                value: auction.counts.watches,
                sub: auction.counts.lot_watches + ' lot watches'
            },
            {
                icon: FaChalkboardUser,
                title: 'Logged In Users',
                value: auction.stats.logged_in_users
            },
            {
                icon: FaUserCheck,
                title: 'Registered Users',
                value: auction.stats.registered_users,
                sub: auction.stats.approved_users + ' approved'
            },
            {
                icon: FaFileInvoiceDollar,
                title: 'Invoices',
                value: formatCurrency(auction.stats.invoice_total, 2) || '$0.00',
                sub: (formatCurrency(auction.stats.invoice_balance, 2) || '$0.00') + ' due'
            },
            {
                icon: FaFileInvoice,
                title: 'Statements',
                value: formatCurrency(auction.stats.statement_total, 2) || '$0.00',
                sub: (formatCurrency(auction.stats.statement_balance, 2) || '$0.00') + ' due'
            },
        ]
    }

    const buttons = auction && auction.company ? (
        <div>
            <Button title="Go to company" color="outline" size="md" href={`/admin/company/${auction.company_id}`}>
                <span className="flex gap-2 items-center">
                    <MdBusiness className="w-5 h-5" />
                    <span>{auction.company.name}</span>
                </span>
            </Button>
        </div>
    ) : ''

    const onSubmitAlert = (alert, then) => {
        const config = auction.config || {}

        config.alert = alert

        update(auction.id, {
            config
        }, () => {
            setAuction({
                ...auction,
                config
            })

            if (then) {
                then()
            }
        })
    }

    if (! auction) {
        return
    }

    return (
        <AuctionLayout id={id} auction={auction} setAuction={setAuction} buttons={buttons}>
            <div className="mb-8">
                <AuctionStats stats={stats} />
            </div>

            {canUpdateUnrestricted && (
                <div className="mb-4">
                    <AlertForm
                        alert={auction.config?.alert || {}}
                        onSubmit={onSubmitAlert}
                        options={(options, setAlertOption) => {
                            return (
                                <label className="flex gap-2 items-center">
                                    <input
                                        type="checkbox"
                                        onChange={(e) => {
                                            setAlertOption('on_lot_details', e.target.checked)
                                        }}
                                        checked={options?.on_lot_details || false}
                                    />
                                    <span>Display on lot details</span>
                                </label>
                            )
                        }}
                    />
                </div>
            )}

            {canUpdateUnrestricted && (
                auction.paused_at ? (
                    <ActionPanel
                        color="amber"
                        title="Resume Auction"
                        action="Resume"
                        onClick={() => setVisibleModal('resume')}
                    >
                        Resume the auction, optionally extending the end time of the auction and any lots.
                    </ActionPanel>
                ) : (
                    <ActionPanel
                        color="amber"
                        title="Pause Auction"
                        action="Pause"
                        onClick={() => setVisibleModal('pause')}
                    >
                        Pause the auction at its current point in time. All timers will stop and no lots will close until it is resumed.
                    </ActionPanel>
                )
            )}

            {canDelete && (
                <ActionPanel
                    color="red"
                    title="Delete Auction"
                    action="Delete"
                    onClick={() => setVisibleModal('delete')}
                >
                    Delete this auction and everything associated with it, including lots, invoices and statements
                </ActionPanel>
            )}

            <PauseAuctionModal
                auction={auction}
                visible={visibleModal}
                setVisible={setVisibleModal}
                then={(auction) => {
                    setAuction(auction)
                }}
            />

            <ResumeAuctionModal
                auction={auction}
                visible={visibleModal}
                setVisible={setVisibleModal}
                then={(auction) => {
                    setAuction(auction)
                }}
            />

            <DeleteAuctionModal
                auction={auction}
                visible={visibleModal}
                setVisible={setVisibleModal}
                then={() => {
                    navigate('/admin/auctions')
                }}
            />
        </AuctionLayout>
    )
}

export default Dashboard