import {
  MdBusiness,
  MdBusinessCenter,
  MdDashboard,
  MdGavel,
  MdHome,
  MdLock,
  MdPermMedia
} from "react-icons/md";

import {
  FaList,
  FaBoxesStacked,
  FaFileInvoice,
  FaFileInvoiceDollar,
  FaGears,
  FaSitemap,
  FaUsers
} from "react-icons/fa6";

import {
  BsFileEarmarkSpreadsheet
} from "react-icons/bs";

// Admin Imports

// Categories
import CategoriesIndex from "views/admin/categories/index";
import CategoriesEdit from "views/admin/categories/edit";


// Auctions
import AuctionsIndex from "views/admin/auctions/index";
import AuctionsDashboard from "views/admin/auctions/dashboard";
import CreateAuction from "views/admin/auctions/create";
import CreateTemplate from "views/admin/auctions/templates/create";
import EditAuction from "views/admin/auctions/edit";
import AuctionLots from "views/admin/auctions/lots";
import AuctionLotPhotos from "views/admin/auctions/photos";
import AuctionLotStats from "views/admin/auctions/stats";
import AuctionBidders from "views/admin/auctions/bidders";
import AuctionConsignors from "views/admin/auctions/consignors";
import AuctionInvoices from "views/admin/auctions/invoices";
import AuctionStatements from "views/admin/auctions/statements";
import AuctionReports from "views/admin/auctions/reports";
import AuctionTemplatesIndex from "views/admin/auctions/templates";
import AuctionApprovalsIndex from "views/admin/auctions/approvals";
import AuctionLotApprovalsIndex from "views/admin/auctions/lot-approvals";
import PendingAuctionsIndex from "views/admin/auctions/pending";
import AuctionNotes from "views/admin/auctions/notes";
import AuctionDocuments from "views/admin/auctions/documents";
import AuctionHighlights from "views/admin/auctions/highlights";

// Lots
import LotsDashboard from "views/admin/lots/dashboard";
import CreateLot from "views/admin/lots/create";
import EditLot from "views/admin/lots/edit";
import LotBids from "views/admin/lots/bids";
import LotPhotos from "views/admin/lots/photos";
import LotWatches from "views/admin/lots/watches";
import LotNotes from "views/admin/lots/notes";
import LotDocuments from "views/admin/lots/documents";
import LotChangeHistory from "views/admin/lots/changes";

// Inventory
import ItemsIndex from "views/admin/items/index";
import ItemDashboard from "views/admin/items/dashboard";
import CreateItem from "views/admin/items/create";
import EditItem from "views/admin/items/edit";
import ItemHistory from "views/admin/items/history";
import ItemPhotos from "views/admin/items/photos";
import ItemNotes from "views/admin/items/notes";
import ItemDocuments from "views/admin/items/documents";

// Reports
import AuctionOpsReport from "views/admin/reports/ops";

// Invoices
import InvoicesIndex from "views/admin/invoices/index";
import EditInvoice from "views/admin/invoices/edit";

// Statements
import StatementsIndex from "views/admin/statements/index";
import EditStatement from "views/admin/statements/edit";

// Clients
import ClientsIndex from "views/admin/clients/index";
import EditClient from "views/admin/clients/edit";
import ClientDashboard from "views/admin/clients/dashboard";
import ClientCompanies from "views/admin/clients/companies";
import ClientUsers from "views/admin/clients/users";

// Companies
import CompaniesIndex from "views/admin/companies/index";
import CompanyDashboard from "views/admin/companies/dashboard";
import EditCompany from "views/admin/companies/edit";
import CompanyAuctions from "views/admin/companies/auctions";
import CompanyCompanies from "views/admin/companies/companies";
import CompanyUsers from "views/admin/companies/users";
import CompanyStatements from "views/admin/companies/statements";

// Users
import UsersIndex from "views/admin/users/index";
import UserDashboard from "views/admin/users/dashboard";
import UserAssociations from "views/admin/users/associations";
import UserCreditCards from "views/admin/users/credit-cards";
import UserNotes from "views/admin/users/notes";
import EditUser from "views/admin/users/edit";
import UserProfile from "views/admin/users/profile";
import Notifications from "views/admin/users/notifications";
import UserBids from "views/admin/users/bids";
import UserInvoices from "views/admin/users/invoices";

// Portals
import PortalsIndex from "views/admin/portals/index";
import PortalDashboard from "views/admin/portals/dashboard";
import PortalLogo from "views/admin/portals/logo";
import EditPortal from "views/admin/portals/edit";

// Media Library
import MediaIndex from "views/admin/media/index";

import DashboardsDefault from "views/admin/dashboards/default";
import AdminDashboard from "views/admin/dashboards/admin";

// Settings
import SettingsIndex from "views/admin/settings/index";
import SettingsArchive from "views/admin/settings/auctions/archive";
import SettingsBidIncrements from "views/admin/settings/auctions/bid-increments";
import SettingsExpenses from "views/admin/settings/auctions/expenses";
import SettingsMaxChargeValue from "views/admin/settings/invoices/max-charge-value";
import SettingsPaymentTypes from "views/admin/settings/auctions/payment-types";
import SettingsPremiums from "views/admin/settings/auctions/premiums";
import SettingsTaxes from "views/admin/settings/auctions/taxes";
import SettingsPrivacyPolicy from "views/admin/settings/company/privacy-policy";
import SettingsTerms from "views/admin/settings/company/terms";
import SettingsCompanyTypes from "views/admin/settings/company/types";

// Reports
import ReportsIndex from "views/admin/reports/index";
import BidderReport from "views/admin/reports/auctions/bidder";
import RevenueReport from "views/admin/reports/auctions/revenue";
import LotsSoldReport from "views/admin/reports/auctions/lots-sold";
import CensusReport from "views/admin/reports/companies/census";

// Main Imports
import AccountBilling from "views/admin/main/account/billing";
import AccountApplications from "views/admin/main/account/application";
import AccountInvoice from "views/admin/main/account/invoice";
import AccountSettings from "views/admin/main/account/settings";
import AccountAllCourses from "views/admin/main/account/courses";
import AccountCoursePage from "views/admin/main/account/coursePage";

import UserNew from "views/admin/main/users/newUser";
import UsersOverview from "views/admin/main/users/overview";
import UsersReports from "views/admin/main/users/reports";

import ProfileSettings from "views/admin/main/profile/settings";
import ProfileOverview from "views/admin/main/profile/overview";
import ProfileNewsfeed from "views/admin/main/profile/newsfeed";

import ApplicationsKanban from "views/admin/main/applications/kanban";
import ApplicationsDataTables from "views/admin/main/applications/dataTables";
import ApplicationsCalendar from "views/admin/main/applications/calendar";

import EcommerceNewProduct from "views/admin/main/ecommerce/newProduct";
import EcommerceProductSettings from "views/admin/main/ecommerce/settingsProduct";
import EcommerceProductPage from "views/admin/main/ecommerce/productPage";
import EcommerceOrderList from "views/admin/main/ecommerce/orderList";
import EcommerceOrderDetails from "views/admin/main/ecommerce/orderDetails";
import EcommerceReferrals from "views/admin/main/ecommerce/referrals";

// Others
import OthersNotifications from "views/admin/main/others/notifications";
//import OthersPricing from 'views/admin/main/others/pricing';
import OthersPricing from "views/admin/main/others/pricing";
import OthersError from "views/admin/main/others/404";
import Buttons from "views/admin/main/others/buttons";
import Messages from "views/admin/main/others/messages";

// Auth Imports
import ForgotPasswordCentered from "views/auth/forgotPassword/ForgotPasswordCenter.jsx";
import ForgotPasswordDefault from "views/auth/forgotPassword/ForgotPasswordDefault.jsx";
import LockCentered from "views/auth/lock/LockCenter.jsx";
import LockDefault from "views/auth/lock/LockDefault.jsx";
import SignInCentered from "views/auth/signIn/SignInCenter.jsx";
import SignInDefault from "views/auth/signIn/SignInDefault.jsx";
import SignUpCentered from "views/auth/signUp/SignUpCenter.jsx";
import SignUpDefault from "views/auth/signUp/SignUpDefault.jsx";
import VerificationCentered from "views/auth/verification/VerificationCenter.jsx";
import VerificationDefault from "views/auth/verification/VerificationDefault.jsx";

const routes = [
  {
  	name: 'Dashboard',
  	layout: '/admin',
  	path: '/dashboard',
  	icon: <MdHome className="text-inherit h-5 w-5" />,
  	component: <AdminDashboard />
  },
  {
  	name: 'Dashboard',
  	layout: '/admin',
  	path: '/dashboard/default',
  	hidden: true,
  	component: <DashboardsDefault />
  },
  {
  	name: 'Inventory',
  	layout: '/admin',
  	path: '/inventory',
  	icon: <FaBoxesStacked className="text-inherit h-5 w-5" />,
  	component: <ItemsIndex />
  },
  {
    name: 'Inventory Item',
    layout: '/admin',
    path: '/item/:id',
    hidden: true,
    component: <ItemDashboard />
  },
  {
    name: 'Create Item',
    layout: '/admin',
    path: '/items/create',
    hidden: true,
    component: <CreateItem />
  },
  {
    name: 'Edit Item',
    layout: '/admin',
    path: '/item/:id/edit',
    hidden: true,
    component: <EditItem />
  },
  {
    name: 'Item History',
    layout: '/admin',
    path: '/item/:id/history',
    hidden: true,
    component: <ItemHistory />
  },
  {
    name: 'Item Photos',
    layout: '/admin',
    path: '/item/:id/photos',
    hidden: true,
    component: <ItemPhotos />
  },
  {
    name: 'Item Notes',
    layout: '/admin',
    path: '/item/:id/notes',
    hidden: true,
    component: <ItemNotes />
  },
  {
    name: 'Item Documents',
    layout: '/admin',
    path: '/item/:id/documents',
    hidden: true,
    component: <ItemDocuments />
  },


  /* Categories */
  {
    name: 'Categories',
    layout: '/admin',
    path: '/categories',
    permission: 'manage_categories',
    icon: <FaList className="text-inherit h-5 w-5" />,
    component: <CategoriesIndex />
  },
  {
    name: 'Edit Category',
    layout: '/admin',
    path: '/categories/:id/edit',
    permission: 'manage_categories',
    hidden: true,
    component: <CategoriesEdit />
  },

  {
    name: 'Create Category',
    layout: '/admin',
    path: '/categories/create',
    permission: 'manage_categories',
    hidden: true,
    component: <CategoriesEdit />
  },



  /*
    Auction Routes
   */

  {
  	name: 'Auctions',
  	layout: '/admin',
  	path: '/auctions',
  	icon: <MdGavel className="text-inherit h-5 w-5" />,
  	component: <AuctionsIndex />
  },

  {
    name: 'Templates',
    layout: '/admin',
    path: '/auctions/templates',
    hidden: true,
    component: <AuctionTemplatesIndex />
  },

  {
    name: 'Registration Approvals',
    layout: '/admin',
    path: '/auctions/approvals',
    hidden: true,
    component: <AuctionApprovalsIndex />
  },

  {
    name: 'Auction Approvals',
    layout: '/admin',
    path: '/auctions/pending',
    hidden: true,
    component: <PendingAuctionsIndex />
  },

  {
    name: 'Lot Approvals',
    layout: '/admin',
    path: '/auctions/lot/approvals',
    hidden: true,
    component: <AuctionLotApprovalsIndex />
  },

  {
    name: 'Auction',
  	layout: '/admin',
  	path: '/auction/:id',
    hidden: true,
  	component: <AuctionsDashboard />
  },

  {
    name: 'Create Auction',
    layout: '/admin',
    path: '/auctions/create',
    hidden: true,
    component: <CreateAuction />
  },

  {
    name: 'Copy Auction',
    layout: '/admin',
    path: '/auction/:id/clone',
    hidden: true,
    component: <CreateAuction />
  },

  {
    name: 'Create Template',
    layout: '/admin',
    path: '/auctions/create/template',
    hidden: true,
    component: <CreateTemplate />
  },

  {
    name: 'Edit Auction',
  	layout: '/admin',
  	path: '/auction/:id/edit',
    hidden: true,
  	component: <EditAuction />
  },

  {
    name: 'Auction Lots',
  	layout: '/admin',
  	path: '/auction/:id/lots',
    hidden: true,
  	component: <AuctionLots />
  },

  {
    name: 'Auction Lot Photos',
  	layout: '/admin',
  	path: '/auction/:id/photos',
    hidden: true,
  	component: <AuctionLotPhotos />
  },

  {
    name: 'Auction Lot Stats',
  	layout: '/admin',
  	path: '/auction/:id/stats',
    hidden: true,
  	component: <AuctionLotStats />
  },

  {
    name: 'Auction Bidders',
  	layout: '/admin',
  	path: '/auction/:id/bidders',
    hidden: true,
  	component: <AuctionBidders />
  },

  {
    name: 'Auction Consignors',
  	layout: '/admin',
  	path: '/auction/:id/consignors',
    hidden: true,
  	component: <AuctionConsignors />
  },

  {
    name: 'Auction Invoices',
  	layout: '/admin',
  	path: '/auction/:id/invoices',
    hidden: true,
  	component: <AuctionInvoices />
  },

  {
    name: 'Auction Statements',
  	layout: '/admin',
  	path: '/auction/:id/statements',
    hidden: true,
  	component: <AuctionStatements />
  },

  {
    name: 'Auction Reports',
  	layout: '/admin',
  	path: '/auction/:id/reports',
    hidden: true,
  	component: <AuctionReports />
  },

  {
    name: 'Auction Notes',
  	layout: '/admin',
  	path: '/auction/:id/notes',
    hidden: true,
  	component: <AuctionNotes />
  },

  {
    name: 'Auction Documents',
  	layout: '/admin',
  	path: '/auction/:id/documents',
    hidden: true,
  	component: <AuctionDocuments />
  },

  {
    name: 'Auction Highlights',
  	layout: '/admin',
  	path: '/auction/:id/highlights',
    hidden: true,
  	component: <AuctionHighlights />
  },

  {
    name: 'Daily Ops Report',
  	layout: '/admin',
  	path: '/reports/ops',
    hidden: true,
  	component: <AuctionOpsReport />
  },

  /*
    Lot Routes
   */

  {
    name: 'Lot',
    layout: '/admin',
    path: '/lot/:id',
    hidden: true,
    component: <LotsDashboard />
  },
  {
    name: 'Create Lot',
    layout: '/admin',
    path: '/auction/:id/lots/create',
    hidden: true,
    component: <CreateLot />
  },
  {
    name: 'Edit Lot',
    layout: '/admin',
    path: '/lot/:id/edit',
    hidden: true,
    component: <EditLot />
  },
  {
    name: 'Lot Bids',
    layout: '/admin',
    path: '/lot/:id/bids',
    hidden: true,
    component: <LotBids />
  },
  {
    name: 'Lot Photos',
    layout: '/admin',
    path: '/lot/:id/photos',
    hidden: true,
    component: <LotPhotos />
  },
  {
    name: 'Lot Watches',
    layout: '/admin',
    path: '/lot/:id/watches',
    hidden: true,
    component: <LotWatches />
  },
  {
    name: 'Lot Notes',
    layout: '/admin',
    path: '/lot/:id/notes',
    hidden: true,
    component: <LotNotes />
  },
  {
    name: 'Lot Documents',
    layout: '/admin',
    path: '/lot/:id/documents',
    hidden: true,
    component: <LotDocuments />
  },
  {
    name: 'Lot Change History',
    layout: '/admin',
    path: '/lot/:id/changes',
    hidden: true,
    component: <LotChangeHistory />
  },

  {
    name: 'Invoices',
    layout: '/admin',
    path: '/invoices',
    permission: 'view_invoices',
    icon: <FaFileInvoice className="text-inherit h-5 w-5" />,
    component: <InvoicesIndex />
  },

  {
    name: 'Edit Invoice',
    layout: '/admin',
    path: '/invoice/:id/edit',
    permission: 'view_invoices',
    hidden: true,
    component: <EditInvoice />
  },

  {
    name: 'Statements',
    layout: '/admin',
    path: '/statements',
    permission: 'view_statements',
    icon: <FaFileInvoiceDollar className="text-inherit h-5 w-5" />,
    component: <StatementsIndex />
  },

  {
    name: 'Edit Statement',
    layout: '/admin',
    path: '/statement/:id/edit',
    permission: 'view_statements',
    hidden: true,
    component: <EditStatement />
  },

  {
    name: 'Clients',
    layout: '/admin',
    path: '/clients',
    permission: 'view_clients',
    icon: <MdBusinessCenter className="text-inherit h-5 w-5" />,
    component: <ClientsIndex />
  },
  {
    name: 'Edit Client',
    layout: '/admin',
    path: '/client/:id/edit',
    permission: 'update_clients',
    hidden: true,
    component: <EditClient />
  },
  {
    name: 'Client Dashboard',
    layout: '/admin',
    path: '/client/:id',
    permission: 'view_clients',
    hidden: true,
    component: <ClientDashboard />
  },
  {
    name: 'Client Companies',
    layout: '/admin',
    path: '/client/:id/companies',
    permission: 'view_clients',
    hidden: true,
    component: <ClientCompanies />
  },
  {
    name: 'Client Users',
    layout: '/admin',
    path: '/client/:id/users',
    permission: 'view_clients',
    hidden: true,
    component: <ClientUsers />
  },
  {
    name: 'Create Client',
    layout: '/admin',
    path: '/clients/create',
    permission: 'create_clients',
    hidden: true,
    component: <EditClient />
  },

  {
    name: 'Companies',
    layout: '/admin',
    path: '/companies',
    permission: 'view_companies',
    icon: <MdBusiness className="text-inherit h-5 w-5" />,
    component: <CompaniesIndex />
  },
  {
    name: 'Company',
    layout: '/admin',
    path: '/company/:id',
    permission: 'view_companies',
    hidden: true,
    component: <CompanyDashboard />
  },
  {
    name: 'Edit Company',
    layout: '/admin',
    path: '/company/:id/edit',
    permission: 'update_companies',
    hidden: true,
    component: <EditCompany />
  },
  {
    name: 'Create Company',
    layout: '/admin',
    path: '/companies/create',
    permission: 'create_companies',
    hidden: true,
    component: <EditCompany />
  },
  {
    name: 'Company Auctions',
    layout: '/admin',
    path: '/company/:id/auctions',
    permission: 'view_companies',
    hidden: true,
    component: <CompanyAuctions />
  },
  {
    name: 'Company Sub-companies',
    layout: '/admin',
    path: '/company/:id/companies',
    permission: 'view_companies',
    hidden: true,
    component: <CompanyCompanies />
  },
  {
    name: 'Company Users',
    layout: '/admin',
    path: '/company/:id/users',
    permission: 'view_companies',
    hidden: true,
    component: <CompanyUsers />
  },
  {
    name: 'Company Statements',
    layout: '/admin',
    path: '/company/:id/statements',
    permission: 'view_companies',
    hidden: true,
    component: <CompanyStatements />
  },

  {
    name: 'Users',
    layout: '/admin',
    path: '/users',
    permission: 'view_users',
    icon: <FaUsers className="text-inherit h-5 w-5" />,
    component: <UsersIndex />
  },
  {
    name: 'Edit User',
    layout: '/admin',
    path: '/user/:id/edit',
    hidden: true,
    component: <EditUser />
  },
  {
    name: 'User Profile',
    layout: '/admin',
    path: '/profile',
    hidden: true,
    component: <UserProfile />
  },
  {
    name: 'Notifications',
    layout: '/admin',
    path: '/notifications',
    hidden: true,
    component: <Notifications />
  },
  {
    name: 'User',
    layout: '/admin',
    path: '/user/:id',
    hidden: true,
    component: <UserDashboard />
  },
  {
    name: 'User Associations',
    layout: '/admin',
    path: '/user/:id/associations',
    hidden: true,
    component: <UserAssociations />
  },
  {
    name: 'User Notes',
    layout: '/admin',
    path: '/user/:id/notes',
    hidden: true,
    component: <UserNotes />
  },
  {
    name: 'User Credit Cards',
    layout: '/admin',
    path: '/user/:id/credit-cards',
    hidden: true,
    component: <UserCreditCards />
  },
  {
    name: 'User Bids',
    layout: '/admin',
    path: '/user/:id/bids',
    hidden: true,
    component: <UserBids />
  },
  {
    name: 'User Invoices',
    layout: '/admin',
    path: '/user/:id/invoices',
    hidden: true,
    component: <UserInvoices />
  },
  {
    name: 'Create User',
    layout: '/admin',
    path: '/users/create',
    hidden: true,
    component: <EditUser />
  },

  {
    name: 'Portals',
    layout: '/admin',
    path: '/portals',
    permission: 'manage_portals',
    icon: <FaSitemap className="text-inherit h-5 w-5" />,
    component: <PortalsIndex />
  },
  {
    name: 'Portal',
    layout: '/admin',
    path: '/portal/:id',
    permission: 'manage_portals',
    hidden: true,
    component: <PortalDashboard />
  },
  {
    name: 'Create Portal',
    layout: '/admin',
    path: '/portals/create',
    permission: 'manage_portals',
    hidden: true,
    component: <EditPortal />
  },
  {
    name: 'Edit Portal',
    layout: '/admin',
    path: '/portal/:id/edit',
    permission: 'manage_portals',
    hidden: true,
    component: <EditPortal />
  },
  {
    name: 'Portal Logo',
    layout: '/admin',
    path: '/portal/:id/logo',
    permission: 'manage_portals',
    hidden: true,
    component: <PortalLogo />
  },

  {
    name: 'Media Library',
    layout: '/admin',
    path: '/media',
    permission: 'access_media_library',
    icon: <MdPermMedia className="text-inherit h-5 w-5" />,
    component: <MediaIndex />
  },

  // --- Reports ---
  {
    name: "Reports",
    layout: '/admin',
    path: "/reports",
    permission: "view_seller_reports",
    icon: <BsFileEarmarkSpreadsheet className="text-inherit h-5 w-5" />,
    component: <ReportsIndex />
  },
  {
    name: "Bidder Report",
    layout: '/admin',
    path: "/reports/auctions/bidder",
    hidden: true,
    permission: "view_global_reports",
    component: <BidderReport />
  },
  {
    name: "Revenue Report",
    layout: '/admin',
    path: "/reports/auctions/revenue",
    hidden: true,
    permission: "view_global_reports",
    component: <RevenueReport />
  },
  {
    name: "Lots Sold Report",
    layout: '/admin',
    path: "/reports/auctions/lots-sold",
    hidden: true,
    permission: "view_seller_reports",
    component: <LotsSoldReport />
  },
  {
    name: "Census Report",
    layout: '/admin',
    path: "/reports/companies/census",
    hidden: true,
    permission: "view_global_reports",
    component: <CensusReport />
  },

  // --- Settings ---
  {
    name: "Settings",
    layout: '/admin',
    path: "/settings",
    permission: "update_settings",
    icon: <FaGears className="text-inherit h-5 w-5" />,
    component: <SettingsIndex />
  },
  {
    name: "Archive",
    layout: '/admin',
    path: "/settings/auctions/archive",
    hidden: true,
    permission: "update_settings",
    component: <SettingsArchive />
  },
  {
    name: "Bid Increments",
    layout: '/admin',
    path: "/settings/auctions/bid-increments",
    hidden: true,
    permission: "update_settings",
    component: <SettingsBidIncrements />
  },
  {
    name: "Expenses",
    layout: '/admin',
    path: "/settings/invoices/expenses",
    hidden: true,
    permission: "update_settings",
    component: <SettingsExpenses />
  },
  {
    name: "Max Charge Value",
    layout: '/admin',
    path: "/settings/invoices/max-charge-value",
    hidden: true,
    permission: "update_settings",
    component: <SettingsMaxChargeValue />
  },
  {
    name: "Premiums",
    layout: '/admin',
    path: "/settings/invoices/premiums",
    hidden: true,
    permission: "update_settings",
    component: <SettingsPremiums />
  },
  {
    name: "Sales Taxes",
    layout: '/admin',
    path: "/settings/invoices/taxes",
    hidden: true,
    permission: "update_settings",
    component: <SettingsTaxes />
  },
  {
    name: "Payment Types",
    layout: '/admin',
    path: "/settings/payment-types",
    hidden: true,
    permission: "update_settings",
    component: <SettingsPaymentTypes />
  },
  {
    name: "Privacy Policy",
    layout: '/admin',
    path: "/settings/company/privacy-policy",
    hidden: true,
    permission: "update_settings",
    component: <SettingsPrivacyPolicy />
  },
  {
    name: "Terms",
    layout: '/admin',
    path: "/settings/company/terms",
    hidden: true,
    permission: "update_settings",
    component: <SettingsTerms />
  },
  {
    name: "Company Types",
    layout: '/admin',
    path: "/settings/company-types",
    hidden: true,
    permission: "update_settings",
    component: <SettingsCompanyTypes />
  },

  // --- Main pages ---
  // {
  //   name: "Main Pages",
  //   path: "/main",
  //   icon: <MdDashboard className="text-inherit h-5 w-5" />,
  //   collapse: true,
  //   items: [
  //     {
  //       name: "Account",
  //       path: "/main/account",
  //       collapse: true,
  //       items: [
  //         {
  //           name: "Billing",
  //           layout: "/admin",
  //           path: "/main/account/billing",
  //           exact: false,
  //           component: <AccountBilling />,
  //         },
  //         {
  //           name: "Application",
  //           layout: "/admin",
  //           path: "/main/account/application",
  //           exact: false,
  //           component: <AccountApplications />,
  //         },
  //         {
  //           name: "Invoice",
  //           layout: "/admin",
  //           path: "/main/account/invoice",
  //           exact: false,
  //           component: <AccountInvoice />,
  //         },
  //         {
  //           name: "Settings",
  //           layout: "/admin",
  //           path: "/main/account/settings",
  //           exact: false,
  //           component: <AccountSettings />,
  //         },
  //         {
  //           name: "All Courses",
  //           layout: "/admin",
  //           path: "/main/account/all-courses",
  //           exact: false,
  //           component: <AccountAllCourses />,
  //         },
  //         {
  //           name: "Course Page",
  //           layout: "/admin",
  //           path: "/main/account/course-page",
  //           exact: false,
  //           component: <AccountCoursePage />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Ecommerce",
  //       path: "/ecommerce",
  //       collapse: true,
  //       items: [
  //         {
  //           name: "New Product",
  //           layout: "/admin",
  //           path: "/main/ecommerce/new-prodcut",
  //           exact: false,
  //           component: <EcommerceNewProduct />,
  //         },
  //         {
  //           name: "Product Settings",
  //           layout: "/admin",
  //           path: "/main/ecommerce/settings",
  //           exact: false,
  //           component: <EcommerceProductSettings />,
  //         },
  //         {
  //           name: "Product Page",
  //           layout: "/admin",
  //           path: "/main/ecommerce/page-example",
  //           exact: false,
  //           component: <EcommerceProductPage />,
  //         },
  //         {
  //           name: "Order List",
  //           layout: "/admin",
  //           path: "/main/ecommerce/order-list",
  //           exact: false,
  //           component: <EcommerceOrderList />,
  //         },
  //         {
  //           name: "Order Details",
  //           layout: "/admin",
  //           path: "/main/ecommerce/order-details",
  //           exact: false,
  //           component: <EcommerceOrderDetails />,
  //         },
  //         {
  //           name: "Referrals",
  //           layout: "/admin",
  //           path: "/main/ecommerce/referrals",
  //           exact: false,
  //           component: <EcommerceReferrals />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Users",
  //       path: "/main/users",
  //       collapse: true,
  //       items: [
  //         {
  //           name: "New User",
  //           layout: "/admin",
  //           path: "/main/users/new-user",
  //           exact: false,
  //           component: <UserNew />,
  //         },
  //         {
  //           name: "Users Overview",
  //           layout: "/admin",
  //           path: "/main/users/users-overview",
  //           exact: false,
  //           component: <UsersOverview />,
  //         },
  //         {
  //           name: "Users Reports",
  //           layout: "/admin",
  //           path: "/main/users/users-reports",
  //           exact: false,
  //           component: <UsersReports />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Applications",
  //       path: "/main/applications",
  //       collapse: true,
  //       items: [
  //         {
  //           name: "Kanban",
  //           layout: "/admin",
  //           path: "/main/applications/kanban",
  //           exact: false,
  //           component: <ApplicationsKanban />,
  //         },
  //         {
  //           name: "Data Tables",
  //           layout: "/admin",
  //           path: "/main/applications/data-tables",
  //           exact: false,
  //           component: <ApplicationsDataTables />,
  //         },
  //         // {
  //         //   name: "Calendar",
  //         //   layout: "/admin",
  //         //   path: "/main/applications/calendar",
  //         //   exact: false,
  //         //   component: <ApplicationsCalendar />,
  //         // },
  //       ],
  //     },
  //     {
  //       name: "Profile",
  //       path: "/main/profile",
  //       collapse: true,
  //       items: [
  //         {
  //           name: "Profile Overview",
  //           layout: "/admin",
  //           path: "/main/profile/overview",
  //           exact: false,
  //           component: <ProfileOverview />,
  //         },
  //         {
  //           name: "Profile Settings",
  //           layout: "/admin",
  //           path: "/main/profile/settings",
  //           exact: false,
  //           component: <ProfileSettings />,
  //         },
  //         {
  //           name: "News Feed",
  //           layout: "/admin",
  //           path: "/main/profile/newsfeed",
  //           exact: false,
  //           component: <ProfileNewsfeed />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Others",
  //       path: "/main/others",
  //       collapse: true,
  //       items: [
  //         {
  //           name: "Notifications",
  //           layout: "/admin",
  //           path: "/main/others/notifications",
  //           exact: false,
  //           component: <OthersNotifications />,
  //         },
  //         // {
  //         //   name: "Pricing",
  //         //   layout: "/auth",
  //         //   path: "/main/others/pricing",
  //         //   exact: false,
  //         //   component: <OthersPricing />,
  //         // },
  //         {
  //           name: "404",
  //           layout: "/admin",
  //           path: "/main/others/404",
  //           exact: false,
  //           component: <OthersError />,
  //         },
  //         {
  //           name: "Buttons",
  //           layout: "/admin",
  //           path: "/main/others/buttons",
  //           exact: false,
  //           component: <Buttons />,
  //         },
  //         {
  //           name: "Messages",
  //           layout: "/admin",
  //           path: "/main/others/messages",
  //           exact: false,
  //           component: <Messages />,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // --- Authentication ---
  {
    name: "Login",
    layout: "/auth",
    path: "/login",
    hidden: true,
    component: <SignInDefault />,
  },
  {
    name: "Forgot Password",
    layout: "/auth",
    path: "/forgot-password",
    hidden: true,
    component: <ForgotPasswordDefault />,
  },
  // {
  //   name: "Authentication",
  //   path: "/auth",
  //   icon: <MdLock className="text-inherit h-5 w-5" />,
  //   collapse: true,
  //   items: [
  //     // --- Sign In ---
  //     {
  //       name: "Sign In",
  //       path: "/sign-in",
  //       collapse: true,
  //       items: [
  //         {
  //           name: "Default",
  //           layout: "/auth",
  //           path: "/sign-in/default",
  //           component: <SignInDefault />,
  //         },
  //         {
  //           name: "Centered",
  //           layout: "/auth",
  //           path: "/sign-in/centered",
  //           component: <SignInCentered />,
  //         },
  //       ],
  //     },
  //     // --- Sign Up ---
  //     {
  //       name: "Sign Up",
  //       path: "/sign-up",
  //       collapse: true,
  //       items: [
  //         {
  //           name: "Default",
  //           layout: "/auth",
  //           path: "/sign-up/default",
  //           component: <SignUpDefault />,
  //         },
  //         {
  //           name: "Centered",
  //           layout: "/auth",
  //           path: "/sign-up/centered",
  //           component: <SignUpCentered />,
  //         },
  //       ],
  //     },
  //     // --- Verification ---
  //     {
  //       name: "Verification",
  //       path: "/verification",
  //       collapse: true,
  //       items: [
  //         {
  //           name: "Default",
  //           layout: "/auth",
  //           path: "/verification/default",
  //           component: <VerificationDefault />,
  //         },
  //         {
  //           name: "Centered",
  //           layout: "/auth",
  //           path: "/verification/centered",
  //           component: <VerificationCentered />,
  //         },
  //       ],
  //     },
  //     // --- Lock ---
  //     {
  //       name: "Lock",
  //       path: "/lock",
  //       collapse: true,
  //       items: [
  //         {
  //           name: "Default",
  //           layout: "/auth",
  //           path: "/lock/default",
  //           component: <LockDefault />,
  //         },
  //         {
  //           name: "Centered",
  //           layout: "/auth",
  //           path: "/lock/centered",
  //           component: <LockCentered />,
  //         },
  //       ],
  //     },
  //     // --- Forgot Password ---
  //     {
  //       name: "Forgot Password",
  //       path: "/forgot-password",
  //       collapse: true,
  //       items: [
  //         {
  //           name: "Default",
  //           layout: "/auth",
  //           path: "/forgot-password/default",
  //           component: <ForgotPasswordDefault />,
  //         },
  //         {
  //           name: "Centered",
  //           layout: "/auth",
  //           path: "/forgot-password/centered",
  //           component: <ForgotPasswordCentered />,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  // 	name: 'RTL Admin',
  // 	layout: '/rtl',
  // 	path: 'rtl-default',
  // 	// icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
  // 	component: () => <MainDashboard /> RTL
  // }
];
export default routes;
