import axios from 'axios'

export const index = (params, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/auctions', {
        params
    }).then(then).catch(response => {

    })
}

export const active = (then) => {
    axios.get(process.env.REACT_APP_API_URL + '/auctions/active').then(then)
}

export const all = (params, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/auctions/all', {
        params
    }).then(then)
}

export const opsReport = (params, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/auctions/reports/ops', {
        params
    }).then(then)
}

export const get = (id, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/auction/' + id)
         .then(then)
}

export const create = (data, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/auctions/create', data)
        .then(then).catch(response => {
            alert('Failed to create auction. Please try again')
        })
}

export const update = (id, data, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/auction/' + id + '/update', data)
        .then(then).catch(response => {
            alert('Failed to update auction. Please try again')
        })
}

export const deleteAuction = (id, then) => {
    axios.delete(process.env.REACT_APP_API_URL + '/auction/' + id + '/delete')
        .then(then).catch(response => {
            alert('Failed to delete auction. Please try again')
        })
}

export const pause = (id, data, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/auction/' + id + '/pause', data)
        .then(then).catch(response => {
            alert('Failed to update auction. Please try again')
        })
}

export const resume = (id, data, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/auction/' + id + '/resume', data)
        .then(then).catch(response => {
            alert('Failed to update auction. Please try again')
        })
}

export const releaseAuthorizations = (id, data, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/auction/' + id + '/release-authorizations', data)
        .then(then).catch(response => {
            alert('Failed to release authorizations. Please try again')
        })
}

export const captureDeposits = (id, data, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/auction/' + id + '/capture-deposits', data)
        .then(then).catch(response => {
            alert('Failed to capture deposits. Please try again')
        })
}

export const lots = (id, params, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/auction/' + id + '/lots', {
        params
    }).then(then)
}

export const highBids = (id, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/auction/' + id + '/highBids').then(then)
}

export const photos = (id, params, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/auction/' + id + '/photos', {
        params
    }).then(then)
}

export const highlights = (id, params, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/auction/' + id + '/highlights', {
        params
    }).then(then)
}

export const stats = (id, params, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/auction/' + id + '/stats', {
        params
    }).then(then)
}

export const bidders = (id, params, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/auction/' + id + '/bidders', {
        params
    }).then(then)
}

export const consignors = (id, params, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/auction/' + id + '/consignors', {
        params
    }).then(then)
}

export const invoices = (id, params, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/auction/' + id + '/invoices', {
        params
    }).then(then)
}

export const statements = (id, params, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/auction/' + id + '/statements', {
        params
    }).then(then)
}

export const getStatuses = (params, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/auctions/statuses', {
        params
    }).then(then)
}

export const getApprovals = (params, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/auctions/approvals', {
        params
    }).then(then)
}

export const getLotApprovals = (params, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/auctions/lots/approvals', {
        params
    }).then(then)
}

export const registerBidder = (id, data, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/auction/' + id + '/bidders/register', data)
         .then(then).catch(response => {
            alert('Failed to register bidder for auction. Please try again')
        })
}

export const updateRegistration = (id, data, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/auctions/registrations/' + id + '/update', data)
         .then(then).catch(response => {
             alert('Failed to update registration. Please try again')
         })
}

export const removeFromAuction = (id, then) => {
    axios.delete(process.env.REACT_APP_API_URL + '/auctions/registrations/' + id + '/remove')
        .then(then)
        .catch(response => {
            alert('Failed to remove registration. Please try again')
        })
}

export const createDeposit = (id, data, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/auctions/registrations/' + id + '/deposits/create', data)
        .then(then).catch(response => {
            alert('Failed to create deposit. Please try again')
        })
}

export const updateDeposit = (id, data, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/auctions/deposits/' + id + '/update', data)
        .then(then).catch(response => {
            alert('Failed to update deposit. Please try again')
        })
}

export const removeDeposit = (id, then) => {
    axios.delete(process.env.REACT_APP_API_URL + '/auctions/deposits/' + id + '/remove')
        .then(then)
        .catch(response => {
            alert('Failed to remove deposit. Please try again')
        })
}

export const approveChanges = (id, then, data = null) => {
    axios.post(process.env.REACT_APP_API_URL + '/auction/' + id + '/approve-changes', data)
         .then(then)
}

export const rejectChanges = (id, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/auction/' + id + '/reject-changes')
         .then(then)
}

export const listModified = (auction) => {
    const modified = []

    auction.pending_approvals.forEach(approval => {
        listApprovalModified(auction, approval, modified)
    })

    return modified
}

export const listApprovalModified = (auction, approval, modified) => {
    const excluded = []

    Object.entries(approval.data).forEach(([k, v]) => {
        if (typeof v === 'object' && v !== null) {
            return
        }

        if (excluded.indexOf(k) >= 0) {
            return
        }

        if (auction[k] != v) {
            modified.push({
                k,
                v
            })
        }
    })
}