import Breadcrumbs from 'components/navbar/Breadcrumbs'
import VerticalMenuLayout from 'components/menu/VerticalMenuLayout'

const PortalLayout = ({ id, portal, children }) => {
    const menu = [
        { name: 'Dashboard', href: '/admin/portal/' + id },
        { name: 'Edit', href: `/admin/portal/${id}/edit` },
        { name: 'Logo', href: `/admin/portal/${id}/logo` },
    ].filter(item => !!item)

    menu.forEach(item => {
        item.current = (window.location.pathname === item.href)
    })

    if (! id) {
        return (
            <div className="w-full">
                {children}
            </div>
        )
    }

    return (
        <>
            <div className="ml-[11px] mt-[-72px] fixed z-10">
                <Breadcrumbs trail={[
                    {
                        href: "/admin/portals",
                        title: "Portals"
                    },
                    {
                        href: "#",
                        title: portal.name
                    }
                ]} />
            </div>

            <VerticalMenuLayout items={menu}>
                <div className="w-full">
                    {children}
                </div>
            </VerticalMenuLayout>
        </>
    )
}

export default PortalLayout