import {useEffect, useMemo, useRef, useState} from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import { Menu } from 'primereact/menu'
import { formatCurrency } from 'helpers/currency'
import { FilterMatchMode } from 'primereact/api'

import {
    FaPencil,
    FaEllipsisVertical,
    FaMagnifyingGlass,
    FaEnvelope
} from "react-icons/fa6";
import {InputText} from 'primereact/inputtext'
import {debounce} from 'lodash'
import { can, useAuth } from 'components/auth/AuthContext'
import { useEmailDispatch } from 'components/email/EmailContext'
import SplitButton from 'components/buttons/SplitButton'

const InvoicesTable = ({ loadInvoices, timestamp, auction }) => {
    const STATUS_PAID = 'paid', STATUS_UNPAID = 'unpaid';

    const [ invoices, setInvoices ] = useState([])
    const [ searchParams, setSearchParams ] = useSearchParams()
    const [ page, setPage ] = useState(1)
    const [ sort, setSort ] = useState({
        by: null,
        dir: null
    })
    const navigate = useNavigate()
    const emailDispatch = useEmailDispatch()
    const toast = useRef(null)
    const [ filters, setFilters ] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'invoices.number': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'auctions.name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'users.last_name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'users.email': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'users.phone': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'invoices.status' : { value: null, matchMode: FilterMatchMode.EQUALS }
    })
    const [ globalFilterValue, setGlobalFilterValue ] = useState('')
    const [ statusFilter, setStatusFilter ] = useState(null)
    const [ checkedInvoices, setCheckedInvoices ] = useState([])
    const [ allChecked, setAllChecked ] = useState(false)

    const auth = useAuth()
    const canEdit = can(auth, 'update_invoices')

    useEffect(() => {
        setPage(
            Number(searchParams.get('page')) || 1
        )

        setSort({
            by: searchParams.get('by'),
            dir: searchParams.get('dir')
        })

        window.scrollTo(0, 0)
    }, [searchParams])

    useEffect(() => {
        loadInvoices({
            page,
            by: sort.by,
            dir: sort.dir,
            filters: JSON.stringify(filters)
        }, ({ data }) => {
            setInvoices(data.invoices)
        })
    }, [page, sort, filters, timestamp])

    const onPage = (e) => {
        setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            page: e.page + 1
        })
    }

    const onSort = (e) => {
        setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            by: e.sortField,
            dir: e.sortOrder
        })
    }

    const onFilter = (e) => {
        setFilters(e.filters)
    }

    const debouncedFilter = useMemo(
        () => debounce(e => {
            onFilter(e)
        }, 500),
        []
    )

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        debouncedFilter({
            filters: _filters
        });
        setGlobalFilterValue(value);
    }

    const loading = false

    const MenuBodyTemplate = (rowData) => {
        const menu = useRef(null);
        const items = [
            canEdit ? {
                label: 'Edit',
                icon: <FaPencil className="w-5 h-5 pr-2" />,
                command: () => {
                    navigate(`/admin/invoice/${rowData.id}/edit`)
                }
            } : {
                label: 'View',
                icon: <FaMagnifyingGlass className="w-5 h-5 pr-2" />,
                command: () => {
                    navigate(`/admin/invoice/${rowData.id}/edit`)
                }
            },
        ]

        return (
            <>
                <Menu model={items} popup ref={menu} id="popup_menu_right" popupAlignment="right" />
                <Button text icon={(
                    <FaEllipsisVertical className="w-5 h-5" />
                )} className="mr-2" onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu_right" aria-haspopup />
            </>
        )
    }

    const currency = (data, field) => {
        return formatCurrency(data[field])
    }

    const filterByStatus = (checked, status) => {
        const value = checked ? status : false

        setStatusFilter(value)

        let _filters = { ...filters }

        _filters['invoices.status'].value = value

        onFilter({
            filters: _filters
        });
    }

    const sendEmail = (title, subject, selected = false, message = '') => {
        if (selected && checkedInvoices.length === 0) {
            toast.current.show({
                severity: 'warn',
                summary: 'Error',
                detail: 'Please select at least one invoice',
                life: 3000
            });
            return
        }

        emailDispatch({
            type: 'show',
            email: {
                modal: {
                    title
                },
                subject,
                message,
                auction_id: auction?.id,
                invoice_ids: selected ? checkedInvoices : null
            }
        })
    }

    const renderHeader = () => {
        return (
            <div className="flex gap-4 items-center">
                <Toast ref={toast} />

                <span className="p-icon-field p-icon-field-left">
                    <FaMagnifyingGlass className="w-5 h-5 p-input-icon" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
                </span>

                <label className="flex gap-2 items-center">
                    <input
                        type="checkbox"
                        checked={statusFilter === STATUS_UNPAID}
                        onChange={(e) => {
                            filterByStatus(e.target.checked, STATUS_UNPAID)
                        }}
                    />
                    <span>View Unpaid</span>
                </label>

                <label className="flex gap-2 items-center">
                    <input
                        type="checkbox"
                        checked={statusFilter === STATUS_PAID}
                        onChange={(e) => {
                            filterByStatus(e.target.checked, STATUS_PAID)
                        }}
                    />
                    <span>View Paid</span>
                </label>

                <div>
                    {canEdit && auction && (
                        <SplitButton
                            items={[
                                { name: 'Email All', onClick: () => sendEmail('Send Email Notification to Buyer', 'Your invoice is ready') },
                                { name: 'Email Paid', onClick: () => sendEmail('Send Paid Email Notification to Buyer', 'An invoice has been paid') },
                                { name: 'Email Unpaid', onClick: () => sendEmail('Send Unpaid Email Notification to Buyer', 'Your invoice is unpaid') },
                                { name: 'Email Selected', onClick: () => sendEmail('Send Email Notification to Buyer', 'Your invoice is ready', true) },
                                { name: 'Email Selected Past Due', onClick: () => sendEmail('Send Past Due Notification to Buyer', 'Your invoice is past due', true) },
                                { name: 'Email Selected Payment Due', onClick: () => sendEmail('Send Payment Due Notification to Buyer', 'Your invoice payment is due', true) },
                                { name: 'Email Selected Paid Receipt', onClick: () => sendEmail('Send Paid Receipt Notification to Buyer', 'Your invoice has been paid', true, 'Your invoice has been paid') },
                            ]}
                            onClick={() => sendEmail('Send Email Notification to Buyer', 'Your invoice is ready')}
                        >
                            <FaEnvelope className="w-5 h-5" />
                        </SplitButton>
                    )}
                </div>
            </div>
        )
    }

    const CheckboxTemplate = (lot) => {
        return (
            <input
                type="checkbox"
                checked={checked(lot)}
                onChange={e => checkInvoice(lot, e.target.checked)}
            />
        )
    }

    const AllCheckboxTemplate = () => {
        return (
            <input
                type="checkbox"
                checked={allChecked}
                onChange={e => checkAll(e.target.checked)}
            />
        )
    }

    const checked = (lot) => {
        return checkedInvoices.indexOf(lot.id) >= 0
    }

    const checkInvoice = (invoice, toggled) => {
        let invoices = [...checkedInvoices]

        if (toggled) {
            if (! checked(invoice)) {
                invoices.push(invoice.id)
            }
        } else {
            invoices = invoices.filter(id => id !== invoice.id)
        }

        setCheckedInvoices(invoices)
    }

    const checkAll = (toggled) => {
        if (toggled) {
            setCheckedInvoices(
                invoices.data.map(invoice => invoice.id)
            )
        } else {
            setCheckedInvoices([])
        }
    }

    const allInvoicesChecked = () => {
        let all = true

        if (! invoices.data) {
            return false
        }

        invoices.data?.forEach((invoice) => {
            if (! checked(invoice)) {
                all = false
            }
        })

        return all
    }

    useEffect(() => {
        setAllChecked(
            allInvoicesChecked()
        )
    }, [checkedInvoices])

    const header = renderHeader()

    return (
        <DataTable className="text-sm" value={invoices.data} lazy dataKey="id" paginator
                   filters={filters} filterDisplay="menu" onFilter={onFilter} globalFilterFields={['invoices.number', 'auctions.name', 'users.first_name', 'users.last_name', 'users.email']}
                   first={invoices.from - 1} rows={invoices.per_page} totalRecords={invoices.total} onPage={onPage}
                   onSort={onSort} sortField={sort.by} sortOrder={sort.dir}
                   rowClassName="cursor-pointer"
                   onRowClick={(e) => {
                       navigate(`/admin/invoice/${e.data.id}/edit`)
                   }}
                   loading={loading} tableStyle={{ minWidth: '75rem' }}
                   emptyMessage="No results"
                   header={header}>
            {canEdit && (
                <Column body={CheckboxTemplate} header={AllCheckboxTemplate} />
            )}
            <Column field="number" header="Number" sortField="invoices.number" filterField="invoices.number" sortable filter
                    body={(data) => {
                        return (
                            <Link to={`/admin/invoice/${data.id}/edit`} className="text-slate-400 hover:text-slate-600">{data.number}</Link>
                        )
                    }}
            />
            <Column field="auction.name" sortField="auctions.name" filterField="auctions.name" sortable filter header="Auction"
                    body={(data) => {
                        return (
                            <Link to={`/admin/auction/${data.auction_id}`} className="text-slate-400 hover:text-slate-600">{data.auction.name}</Link>
                        )
                    }}
            />
            <Column field="buyer.name" sortField="users.last_name" filterField="users.last_name" sortable filter header="Buyer"
                    body={(data) => {
                        return (
                            <Link to={`/admin/user/${data.buyer_id}/edit`} className="text-slate-400 hover:text-slate-600">{data.buyer.name}</Link>
                        )
                    }}
            />
            <Column field="buyer.email" sortField="users.email" filterField="users.email" sortable filter header="Email" />
            <Column field="buyer.phone" sortField="users.phone" filterField="users.phone" sortable filter header="Phone" />
            <Column field="dates.created" sortField="invoices.created_at" sortable header="Created" />
            <Column field="counts.items" header="Lots" />
            <Column field="total" sortField="invoices.total" sortable header="Total"
                    body={(data) => currency(data, 'total')} />
            <Column field="payments" sortField="invoices.payments" sortable header="Payments"
                    body={(data) => currency(data, 'payments')} />
            <Column field="balance" sortField="invoices.balance" sortable header="Balance"
                    body={(data) => currency(data, 'balance')} />
            <Column
                field="status" sortField="invoices.status" filterField="invoices.status" sortable filter header="Status"
                body={(invoice) => {
                    return (
                        <div className={"uppercase font-bold " + (invoice.status === 'paid' ? 'text-green-600' : 'text-red-600')}>
                            {invoice.status}
                        </div>
                    )
                }}
            />
            <Column headerStyle={{ width: '4rem' }} body={MenuBodyTemplate}></Column>
        </DataTable>
    )
}

export default InvoicesTable