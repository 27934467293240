import { Dialog } from 'primereact/dialog'
import Button from 'components/buttons/Button'
import { captureDeposits } from 'services/auctions'
import { useNotificationsDispatch } from 'components/notifications/NotificationsContext'

const CaptureDepositsModal = ({ auction, visible, setVisible, then }) => {
    const notify = useNotificationsDispatch()

    const capture = () => {
        captureDeposits(auction.id, {}, () => {
            setVisible(false)

            notify({
                message: 'Deposits have been captured.',
                title: 'Success!',
                type: 'success',
            })

            if (then) {
                then(auction)
            }
        })
    }

    const header = (
        <div className="text-lg font-bold">
            Capture Deposits
        </div>
    )

    const footer = (
        <div>
            <Button color="outline" onClick={() => setVisible(false)}>
                Cancel
            </Button>
            <Button onClick={capture}>
                Capture Deposits
            </Button>
        </div>
    )

    return (
        <Dialog modal draggable={false} resizable={false} visible={visible}
                header={header} footer={footer} style={{ width: "40rem" }}
                onHide={() => setVisible(false)}
        >
            <p>
                This will capture the deposits of any users who have an invoice and apply that deposit to their invoice.
            </p>

            <p className="my-4">
                The deposits it is capturing are the deposits that were created when the user registered for the auction
                (if the auction was configured to do so).
            </p>

            <p>
                It will not do anything to deposits made by non-winning users and it will only capture deposits, not authorizations.
            </p>
        </Dialog>
    )
}

export default CaptureDepositsModal