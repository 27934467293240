import { useState } from 'react'
import { Dialog } from 'primereact/dialog'
import Button from 'components/buttons/Button'
import { releaseAuthorizations } from 'services/auctions'
import { useNotificationsDispatch } from 'components/notifications/NotificationsContext'

const ReleaseAuthorizationsModal = ({ auction, visible, setVisible, then }) => {
    const notify = useNotificationsDispatch()
    const [ includeWinning, setIncludeWinning ] = useState(false)

    const release = () => {
        releaseAuthorizations(auction.id, {
            'include_winning': includeWinning ? 1 : 0
        }, () => {
            setVisible(false)

            notify({
                message: 'Authorizations have been released.',
                title: 'Success!',
                type: 'success',
            })

            if (then) {
                then(auction)
            }
        })
    }

    const header = (
        <div className="text-lg font-bold">
            Release Authorizations
        </div>
    )

    const footer = (
        <div>
            <Button color="outline" onClick={() => setVisible(false)}>
                Cancel
            </Button>
            <Button onClick={release}>
                Release Authorizations / Deposits
            </Button>
        </div>
    )

    return (
        <Dialog modal draggable={false} resizable={false} visible={visible}
                header={header} footer={footer} style={{ width: "40rem" }}
                onHide={() => setVisible(false)}
        >
            <p>
                Running this will release all authorizations and deposits of non-winning users made by users when they
                registered for the auction (if the auction was configured to do require authorizations/deposits).
            </p>

            <p className="my-4">
                If the user does not have an invoice, and thus didn't win anything, any authorizations made against their card
                during auction registration will be released.
            </p>

            <p className="my-4">
                It will not do anything to deposits and authorizations made by users who have an invoice (unless the &quot;Include Winning Users&quot; checkbox is checked below).
            </p>

            <label className="flex gap-2 items-center">
                <input
                    type="checkbox"
                    onChange={(e) => {
                        setIncludeWinning(e.target.checked)
                    }}
                    checked={includeWinning}
                />

                <span>Include Winning Users</span>
            </label>
        </Dialog>
    )
}

export default ReleaseAuthorizationsModal