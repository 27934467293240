import VerticalMenu from './VerticalMenu'

const VerticalMenuLayout = ({ items, children }) => {
    return (
        <div className="flex gap-6 pt-4 flex-col sm:flex-row">
            <VerticalMenu items={items} />

            {children}
        </div>
    )
}

export default VerticalMenuLayout