import React from "react";
import Dropdown from "components/dropdown";
import { FaBars } from "react-icons/fa6";
import { Link } from "react-router-dom";
import navbarimage from "assets/img/layout/Navbar.png";
import { BsArrowBarUp } from "react-icons/bs";
import Configurator from "./Configurator";
import { RiMoonFill, RiSunFill } from 'react-icons/ri';
// import Configurator from './Configurator';
import {
  IoMdNotificationsOutline,
  IoMdInformationCircleOutline,
} from 'react-icons/io'
import {
  FaUserSecret
} from 'react-icons/fa6'
import { useDispatch, useSelector } from 'react-redux'
import {
  updatePermissions,
  markNotificationRead,
  markAllNotificationsRead,
  useAuth,
  useAuthDispatch
} from 'components/auth/AuthContext'
import { useNavigate } from 'react-router-dom'
import { stopImpersonating, logout } from 'store/user'
import Breadcrumbs from './Breadcrumbs'
import Search from './Search'

const Navbar = (props) => {
  const {
    onOpenSidenav,
    brandText,
    mini,
    // setMini,
    // theme,
    // setTheme,
    hovered,
  } = props;

  const [darkmode, setDarkmode] = React.useState(
    document.body.classList.contains("dark")
  );

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const auth = useAuth()
  const authDispatch = useAuthDispatch()
  const { user } = useSelector(state => state.user)

  const doLogout = (e) => {
    e.preventDefault()

    dispatch(logout(then => {
      navigate('/auth/login')
    }))
  }

  const stopImpersonation = (close) => {
    dispatch(stopImpersonating(user, () => {
      updatePermissions(authDispatch, () => {
        close()
        navigate('/admin/dashboard')
      })
    }, () => {

    }))
  }

  const markRead = (id) => {
    markNotificationRead(authDispatch, id)
  }

  const markAllRead = () => {
    markAllNotificationsRead(authDispatch)
  }

  return (
    <nav
      className={`duration-175 linear fixed top-0 left-3 md:top-3 md:left-auto md:right-3 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/30 transition-all ${
        mini === false
          ? "w-[calc(100vw_-_6%)] md:w-[calc(100vw_-_8%)] lg:w-[calc(100vw_-_6%)] xl:w-[calc(100vw_-_285px)] 2xl:w-[calc(100vw_-_300px)]"
          : mini === true && hovered === true
          ? "w-[calc(100vw_-_6%)] md:w-[calc(100vw_-_8%)] lg:w-[calc(100vw_-_6%)] xl:w-[calc(100vw_-_285px)] 2xl:w-[calc(100vw_-_300px)]"
          : "w-[calc(100vw_-_6%)] md:w-[calc(100vw_-_8%)] lg:w-[calc(100vw_-_6%)] xl:w-[calc(100vw_-_180px)] 2xl:w-[calc(100vw_-_195px)]"
      }  p-2 backdrop-blur-xl dark:bg-[#0b14374d] md:top-4 md:right-[30px] xl:top-[20px]`}
    >
      <div className="ml-[6px] h-[78px]">
        {brandText && (
            <Breadcrumbs trail={[
              {
                href: "#",
                title: brandText
              }
            ]} />
        )}
      </div>

      <div className="order-first sm:order-last mb-2 sm:mb-0 flex gap-4 items-center">
        <span
            className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden "
            onClick={onOpenSidenav}
        >
          <FaBars className="h-5 w-5" />
        </span>

      <div className="relative mt-[3px] flex h-[61px] w-full sm:w-[355px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[365px] md:flex-grow-0 md:gap-1 xl:w-[365px] xl:gap-2">
        <Search />
        {/* start Notification */}
        <Dropdown
          button={
            <div className="cursor-pointer relative">
              {auth.notifications?.total > 0 && (
                  <div className="rounded-full absolute top-0 right-0 -mt-1 -mr-1 px-1.5 bg-red-600 text-white text-2xs">
                    {auth.notifications?.total}
                  </div>
              )}
              <IoMdNotificationsOutline className="ml-2 h-5 w-5 text-gray-600 dark:text-white" />
            </div>
          }
          animation="origin-[65%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
          children={close => (
            <div className="flex w-[360px] flex-col gap-3 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none sm:w-[460px]">
              <div className="flex items-center justify-between">
                <p className="text-base font-bold text-navy-700 dark:text-white">
                  Notifications
                </p>
                {auth.notifications?.data.length > 0 && (
                    <p className="text-sm font-bold text-navy-700 dark:text-white cursor-pointer"
                       onClick={() => {
                         markAllRead()
                         close()
                       }}
                    >
                      Mark all read
                    </p>
                )}
                <Link className="text-sm font-bold text-navy-700 dark:text-white cursor-pointer"
                      to="/admin/notifications"
                      onClick={close}
                >
                  View all
                </Link>
              </div>

              {auth.notifications?.data.length === 0 && (
                  <p className="italic">You have no unread notifications</p>
              )}

              {auth.notifications?.data.map((notification) => {
                return (
                  <button key={notification.id} onClick={() => {
                    markRead(notification.id)

                    if (notification.lot) {
                      navigate('/admin/lot/' + notification.lot.id + '/edit')
                    } else if (notification.auction) {
                      navigate('/admin/auction/' + notification.auction.id + '/edit')
                    } else if (notification.user) {
                      navigate('/admin/user/' + notification.user.id)
                    } else if (notification.invoice) {
                      navigate('/admin/invoice/' + notification.invoice.id + '/edit')
                    }

                    close()
                  }} className="flex w-full items-center">
                    <div className="ml-2 flex h-full w-full flex-col justify-center rounded-lg px-1 text-sm">
                      <p className="mb-1 text-left text-sm font-bold text-gray-900 dark:text-white">
                        {notification.data.title}
                      </p>
                      <div className="font-base text-left text-2xs text-gray-900 dark:text-white">
                        {new Date(notification.created_at).toLocaleString()}
                      </div>
                      <div className="flex gap-2 items-center font-base text-left text-2xs text-gray-900 dark:text-white">
                        {notification.lot && (
                            <span>
                              Lot #{notification.lot.number}: {notification.lot.name}
                            </span>
                        )}
                        {notification.auction && (
                            <span>
                              Auction: {notification.auction.name}
                            </span>
                        )}
                      </div>
                    </div>
                  </button>
                )
              })}
            </div>
          )}
          classNames={"py-2 top-4 -left-[230px] md:-left-[440px] w-max"}
        />
        <div
          className="cursor-pointer text-gray-600"
          onClick={() => {
            if (darkmode) {
              document.body.classList.remove('dark');
              setDarkmode(false);
            } else {
              document.body.classList.add('dark');
              setDarkmode(true);
            }
          }}
        >
          {darkmode ? (
            <RiSunFill className="h-5 w-5 text-gray-600 dark:text-white" />
          ) : (
            <RiMoonFill className="h-5 w-5 text-gray-600 dark:text-white" />
          )}
        </div>
        <div
          className="cursor-pointer text-gray-600"
          onClick={() => {
            if (darkmode) {
              document.body.classList.remove("dark");
              setDarkmode(false);
            } else {
              document.body.classList.add("dark");
              setDarkmode(true);
            }
          }}
        ></div>
        {/* Profile & Dropdown */}
        <Dropdown
          button={
            <span className="inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-500 text-white">
              {user?.impersonated ? (
                <FaUserSecret className="w-5 h-5" />
              ) : (
                  <span className="font-medium leading-none">{user?.initials}</span>
              )}
            </span>
          }
          children={close => (
            <div className="flex h-max w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat pb-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
              <div className="mt-3 ml-4">
                <div className="flex items-center gap-2">
                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                    👋 Hey, {user?.first_name}
                  </p>{" "}
                </div>
              </div>
              <div className="mt-3 h-px w-full bg-gray-200 dark:bg-white/20 " />

              <div className="mt-3 ml-4 flex flex-col">
                <Link
                  to="/admin/profile"
                  onClick={() => close()}
                  className="text-sm text-gray-800 dark:text-white hover:dark:text-white"
                >
                  Profile
                </Link>

                {user?.impersonated && (
                    <a
                        href="#"
                        onClick={() => stopImpersonation(close)}
                        className="mt-3 text-sm font-medium text-red-500 hover:text-red-500"
                    >
                      Stop Impersonation
                    </a>
                )}

                <a
                  href="#"
                  onClick={doLogout}
                  className="mt-3 text-sm font-medium text-red-500 hover:text-red-500"
                >
                  Log Out
                </a>
              </div>
            </div>
          )}
          classNames={"py-2 top-8 -left-[180px] w-max"}
        />
      </div>
      </div>
    </nav>
  );
};

export default Navbar;
