import { useEffect, useState } from 'react'
import { useAuth, can } from 'components/auth/AuthContext'
import { active } from 'services/auctions'
import { loadDashboardStats } from 'services/stats'
import Button from 'components/buttons/Button'
import { AuctionStats } from '../../auctions/components/AuctionStats'
import {
    FaChartLine,
    FaChevronRight,
    FaClock,
    FaBoxesStacked,
    FaChalkboardUser,
    FaFileInvoice,
    FaFileInvoiceDollar,
    FaUsers
} from 'react-icons/fa6'
import {
    MdGavel,
} from 'react-icons/md'
import { formatCurrency } from 'helpers/currency'
import pluralize from 'pluralize'

const Dashboard = () => {
    const auth = useAuth()
    const canViewGlobalStats = can(auth, 'view_global_stats')
    const canViewOpsReport = can(auth, 'view_ops_report')

    const [ auctions, setAuctions ] = useState([])
    const [ counts, setCounts ] = useState({})
    const [ stats, setStats ] = useState([])

    useEffect(() => {
        active(({ data }) => {
            setAuctions(data.auctions)
        })

        loadDashboardStats(({ data }) => {
            setCounts(data)
        })
    }, [])

    useEffect(() => {
        setStats(
            buildStats()
        )
    }, [counts])

    const buildStats = () => {
        let stats = [
            {
                icon: MdGavel,
                title: 'Active Auctions',
                value: counts.active_auctions || 0
            },
            {
                icon: FaBoxesStacked,
                title: 'Inventory On Hand',
                value: counts.inventory_on_hand || 0
            },
        ]

        if (canViewGlobalStats) {
            stats = [
                ...stats,
                {
                    icon: FaUsers,
                    title: 'Total Users',
                    value: counts.total_users || 0
                },
                {
                    icon: FaChalkboardUser,
                    title: 'Logged In Users',
                    value: counts.logged_in_users || 0
                }
            ]
        }

        return stats
    }

    return (
        <div className="mt-6">
            <div className="pl-2">
                <AuctionStats stats={stats} condensed={true} />
            </div>

            <div className="pt-8">
                <div className="flex flex-col gap-2 mb-4 sm:flex-row sm:gap-8 items-center">
                    <h1 className="text-[33px] capitalize font-bold text-navy-700 dark:text-white">Active Auctions</h1>

                    {canViewOpsReport && (
                        <Button href="/admin/reports/ops">
                            <span className="inline-flex items-center gap-2">
                                <span>Daily Ops Report</span>
                                <FaChevronRight className="w-5 h-5" />
                            </span>
                        </Button>
                    )}
                </div>

                <div className="grid grid-cols-1 gap-4 xs:grid-cols-2 sm:grid-cols-5 sm:gap-8 items-center">
                    {auctions.length == 0 && (
                        <p className="italic">You have no active auctions</p>
                    )}
                    {auctions.map((auction) => (
                        <div key={auction.id} className="group text-center rounded-lg shadow-lg bg-white">
                            <div className="aspect-h-1 aspect-w-1 w-full h-[160px] overflow-hidden rounded-lg bg-white xl:aspect-h-8 xl:aspect-w-7 rounded-t-lg">
                                <img
                                    src={auction.image?.src}
                                    alt={auction.image?.alt}
                                    className="h-full max-w-[90%] object-contain object-center group-hover:opacity-75 mx-auto"
                                />
                            </div>

                            <h3 className="mt-4 h-16 text-sm text-neutral-900">{auction.name}</h3>

                            <div className="flex flex-col items-center gap-2 text-xs pt-2 pb-4">
                                <div className="flex gap-2 items-center">
                                    <MdGavel className="w-4 h-4" />
                                    {auction.counts.lots} {pluralize('lot', auction.counts.lots)}{', '}
                                    {auction.counts.views} {pluralize('view', auction.counts.views)}{', '}
                                    {auction.counts.watches} {pluralize('watch', auction.counts.watches)}
                                </div>

                                <div className="flex gap-2 items-center">
                                    <FaChartLine className="w-4 h-4" />
                                    {auction.counts.bids} {pluralize('bid', auction.counts.bids)} for a total of {formatCurrency(auction.stats.bid_total, 0) || '$0.00'}
                                </div>

                                <div className="flex gap-2 items-center">
                                    <FaFileInvoiceDollar className="w-4 h-4" />
                                    {formatCurrency(auction.stats.invoice_balance, 0) || '$0.00'} invoice balance
                                </div>

                                <div className="flex gap-2 items-center">
                                    <FaFileInvoice className="w-4 h-4" />
                                    {formatCurrency(auction.stats.statement_balance, 0) || '$0.00'} statement balance
                                </div>

                                <div className="flex gap-2 items-center">
                                    <FaClock className="w-4 h-4" />
                                    <span className="text-neutral-700">Ends:</span> {auction.dates.ends}
                                </div>
                            </div>

                            <a href={"/admin/auction/" + auction.id} className="flex gap-1 items-center justify-center bg-slate-800 group-hover:bg-slate-700 text-white uppercase py-1 rounded-b-lg">
                                View Auction <FaChevronRight className="w-4 h-4" />
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Dashboard