import { useState } from 'react'
import { MdGavel } from 'react-icons/md'
import Breadcrumbs from 'components/navbar/Breadcrumbs'
import VerticalMenuLayout from 'components/menu/VerticalMenuLayout'
import NoteDisplay from 'components/notes/NoteDisplay'
import Btn from 'components/buttons/Button'
import MenuBar from 'components/menu/MenuBar'
import AssignModal from './modals/AssignModal'

const ItemLayout = ({ id, item, children }) => {
    const [ showAssignToAuctionModal, setShowAssignToAuctionModal ] = useState(false)

    const menu = [
        { name: 'Dashboard', href: '/admin/item/' + id },
        item?.editable ? { name: 'Edit', href: `/admin/item/${id}/edit` } : null,
        { name: 'Photos', href: `/admin/item/${id}/photos` },
        { name: 'History', href: `/admin/item/${id}/history` },
        { name: 'Documents', href: `/admin/item/${id}/documents` },
        { name: 'Notes', href: `/admin/item/${id}/notes` },
    ].filter(item => !!item)

    menu.forEach(menuItem => {
        menuItem.current = (window.location.pathname === menuItem.href)
    })

    return (
        <>
            <div className="ml-[11px] mt-[-72px] fixed z-10">
                <Breadcrumbs trail={[
                    {
                        href: "/admin/inventory",
                        title: "Inventory"
                    },
                    {
                        href: "#",
                        title: item?.name
                    }
                ]} />
            </div>

            <VerticalMenuLayout items={menu}>
                <div className="w-full">
                    <MenuBar>
                        {item?.qty > 0 && item?.editable && (
                            <Btn
                                onClick={() => {
                                    setShowAssignToAuctionModal(true)
                                }}
                            >
                                    <span className="flex gap-2 items-center">
                                        <MdGavel className="w-5 h-5" />
                                        <span>Assign to Auction</span>
                                    </span>
                            </Btn>
                        )}
                    </MenuBar>

                    <div className="w-full">
                        <NoteDisplay notes={item?.notes} />
                    </div>

                    {children}
                </div>
            </VerticalMenuLayout>

            <AssignModal
                open={showAssignToAuctionModal}
                setOpen={setShowAssignToAuctionModal}
                item={item}
            />
        </>
    )
}

export default ItemLayout