import { useEffect, useState } from 'react'
import Breadcrumbs from 'components/navbar/Breadcrumbs'
import MenuBar from 'components/menu/MenuBar'
import VerticalMenuLayout from 'components/menu/VerticalMenuLayout'
import NoteDisplay from 'components/notes/NoteDisplay'
import SplitButton from 'components/buttons/SplitButton'
import { can, useAuth } from 'components/auth/AuthContext'
import { update } from 'services/auctions'
import Button from 'components/buttons/Button'
import { useNotificationsDispatch } from 'components/notifications/NotificationsContext'
import {
    FaCircleCheck
} from 'react-icons/fa6'
import {
    MdPublish,
    MdUnpublished
} from 'react-icons/md'
import Stagger from './Stagger'

const AuctionLayout = ({ id, auction, setAuction, children, buttons, showStagger, setShowStagger, afterStagger }) => {
    const auth = useAuth()
    const canUpdate = can(auth, 'update_active_auctions')
    const canViewBids = can(auth, 'view_bids')
    const canViewReports = can(auth, 'view_auctions_reports_seller')
    const notify = useNotificationsDispatch()

    const [ showStaggerLotsModal, setShowStaggerLotsModal ] = useState(false)

    useEffect(() => {
        if (showStagger) {
            setShowStaggerLotsModal(true)
        }
    }, [showStagger])

    useEffect(() => {
        if (! showStaggerLotsModal && setShowStagger) {
            setShowStagger(false)
        }
    }, [showStaggerLotsModal])

    let menu = [
        { name: 'Dashboard', href: '/admin/auction/' + id },
        { name: 'Edit', href: `/admin/auction/${id}/edit` },
        { name: 'Highlights', href: `/admin/auction/${id}/highlights` },
        { name: 'Documents', href: `/admin/auction/${id}/documents` },
        { name: 'Lots', href: `/admin/auction/${id}/lots`, count: auction?.counts.lots },
        { name: 'Lot Photos', href: `/admin/auction/${id}/photos` },
        canViewBids ? { name: 'Lot Stats', href: `/admin/auction/${id}/stats` } : null,
        canViewBids ? { name: 'Bidders', href: `/admin/auction/${id}/bidders`, count: auction?.counts.registrants } : null,
        canViewBids ? { name: 'Consignors', href: `/admin/auction/${id}/consignors`, count: auction?.counts.consignors } : null,
        { name: 'Invoices', href: `/admin/auction/${id}/invoices`, count: 0 },
        { name: 'Statements', href: `/admin/auction/${id}/statements`, count: 0 },
        canViewReports ? { name: 'Reports', href: `/admin/auction/${id}/reports` } : null,
        { name: 'Notes', href: `/admin/auction/${id}/notes` },
    ].filter(item => !!item)

    menu.forEach(item => {
        item.current = (window.location.pathname === item.href)
    })

    if (! auction) {
        return
    }

    if (auction.is_template) {
        menu = menu.filter(item => ['Edit', 'Documents', 'Highlights'].indexOf(item.name) >= 0)
    }

    const updateAuctionStatus = (status) => {
        update(auction.id, {
            status
        }, ({ data }) => {
            setAuction(data.auction)

            notify({
                message: 'Auction status has been updated.',
                title: 'Success!',
                type: 'success',
            })
        })
    }

    return (
        <>
            <div className="ml-[11px] mt-[-72px] fixed z-10">
                <Breadcrumbs trail={[
                    auction.is_template ? {
                        href: "/admin/auctions/templates",
                        title: "Templates"
                    } : {
                        href: "/admin/auctions",
                        title: "Auctions"
                    },
                    {
                        href: "/admin/auction/" + auction.id,
                        title: auction.name
                    }
                ]} />
            </div>

            <VerticalMenuLayout items={menu}>
                <div className="w-full">
                    {! auction.is_template && (
                        <MenuBar justify="start">
                            <div className="flex items-center gap-4">
                                <div>
                                    <span>Status: </span>
                                    <span>{auction.status_label}</span>
                                </div>

                                {canUpdate && auction.status === 'pending' && (
                                    <Button color="outline" size="md" onClick={() => updateAuctionStatus('ready')}>
                                        <span className="flex gap-2 items-center">
                                            <FaCircleCheck className="w-4 h-4" />
                                            <span>Set Ready</span>
                                        </span>
                                    </Button>
                                )}

                                {canUpdate && ['pending', 'ready'].indexOf(auction.status) >= 0 && (
                                    <Button color="outline" size="md" onClick={() => updateAuctionStatus('active')}>
                                        <span className="flex gap-2 items-center">
                                            <MdPublish className="w-5 h-5" />
                                            <span>Publish</span>
                                        </span>
                                    </Button>
                                )}

                                {canUpdate && ['active'].indexOf(auction.status) >= 0 && (
                                    <Button color="outline" size="md" onClick={() => updateAuctionStatus('ready')}>
                                        <span className="flex gap-2 items-center">
                                            <MdUnpublished className="w-5 h-5" />
                                            <span>Unpublish</span>
                                        </span>
                                    </Button>
                                )}
                            </div>

                            {auction.links.index && (
                                <div className="flex justify-start items-center gap-4">
                                    <SplitButton
                                        url={auction.links.index}
                                        target="_blank"
                                        label="Preview"
                                        items={[
                                            { name: 'Landing Page', href: auction.links.show, target: '_blank' }
                                        ]}
                                    />

                                    {buttons}
                                </div>
                            )}
                        </MenuBar>
                    )}

                    <div className="w-full">
                        <NoteDisplay notes={auction.related_notes} />
                    </div>

                    <Stagger
                        id={id}
                        auction={auction}
                        after={afterStagger}
                        show={showStaggerLotsModal}
                        setShow={setShowStaggerLotsModal}
                    />

                    {children}
                </div>
            </VerticalMenuLayout>
        </>
    )
}

export default AuctionLayout