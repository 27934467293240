/* eslint-disable */

import { useEffect, useState } from "react";
import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import { useAuth, can } from "components/auth/AuthContext"

import SidebarCard from "components/sidebar/components/SidebarCard";
import {
  renderThumb,
  renderTrack,
  renderView,
  renderViewMini,
} from "components/scrollbar/Scrollbar";
import { Scrollbars } from "react-custom-scrollbars-2";
import logo from "assets/img/peak/logo.svg";
import routes from "routes";
import Card from "components/card";

function SidebarHorizon(props) {
  const auth = useAuth()

  const { open, onClose, autoclose = false, variant, mini, hovered, setHovered } = props;
  const [ filteredRoutes, setFilteredRoutes ] = useState(routes)

  useEffect(() => {
    setFilteredRoutes(
        routes.filter(route => {
          if (typeof route.permission === 'undefined') {
            return true
          }

          return can(auth, route.permission)
        })
    )
  }, [auth])

  return (
    <div
      className={`sm:none ${
        mini === false
          ? "w-[220px]"
          : mini === true && hovered === true
          ? "w-[220px]"
          : "w-[220px] xl:!w-[120px]"
      } duration-175 linear fixed !z-50 min-h-full transition-all md:!z-50 lg:!z-50 xl:!z-0 ${
        variant === "auth" ? "xl:hidden" : "xl:block"
      } ${open ? "" : "-translate-x-[105%]"}`}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Card
        extra={`ml-3 w-full h-[96.5vh] sm:mr-4 sm:my-4 m-7 !rounded-[20px]`}
      >
        <Scrollbars
          autoHide
          renderTrackVertical={renderTrack}
          renderThumbVertical={renderThumb}
          renderView={
            mini === false
              ? renderView
              : mini === true && hovered === true
              ? renderView
              : renderViewMini
          }
        >
          <div className="flex h-full flex-col justify-between">
            <div>
              <span
                className="absolute top-4 right-4 block cursor-pointer xl:hidden"
                onClick={onClose}
              >
                <HiX />
              </span>
              <div className={`flex px-12 py-8 items-center `}>
                <div
                  className={`${
                    mini === false
                      ? "block"
                      : mini === true && hovered === true
                      ? "block"
                      : "hidden"
                  }`}
                >
                  <img src={logo} alt="Peak Auto Auctions" className="w-full" />
                </div>
                <div
                  className={`mt-1 ml-1 h-2.5 font-poppins text-[26px] font-bold uppercase text-navy-700 dark:text-white ${
                    mini === false
                      ? "hidden"
                      : mini === true && hovered === true
                      ? "hidden"
                      : "block"
                  }`}
                >
                  PEAK
                </div>
              </div>

              {/* Nav item */}
              <ul className="mt-12">
                <Links mini={mini} hovered={hovered} routes={filteredRoutes}
                       onClose={onClose}
                       autoclose={autoclose}
                />
              </ul>
            </div>
          </div>
        </Scrollbars>
      </Card>
    </div>
  );
}

export default SidebarHorizon;
